.App {
  text-align: center;
  background-color: #FEFEFE;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

/* #hubspot-messages-iframe-container.widget-align-right {
right:0 !important;
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.wholeDiv {
  /* height: 100%;
  width: 100%; */
  position: absolute;
  top: 0;
  z-index: 999999999999999999999;
  display: none;
}
.dummy{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}