
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.carousel-indicators button {
  width: 0.4rem !important;
  height: 0.4rem !important;
  border-radius: 50%;
  background-color: #086972 !important;
}

.carousel-indicators{
  bottom:-20% !important;
}
.carousel-control-prev-icon,.carousel-control-next-icon  {
  background-color:#F8FFFF;
  border:#086972 1px solid;
  border-radius:50%;
  height:20px !important;
  width:20px !important;
}

.carousel-control-prev{
  justify-content: flex-start !important;
  opacity:1 !important;
}
.carousel-control-next{
  justify-content: flex-end !important;
  opacity:1 !important;
}
.carousel-inner{
  padding:0 1% !important;
}

.css-eg0mwd-MuiSlider-thumb{
  background: url('./pages/Dashboard/VideoPlayer/images/union.svg') no-repeat top;
  background-size:cover;
  opacity:1;
  background-color: none;
  z-index: 999;
  box-shadow: none;
}

.css-eg0mwd-MuiSlider-thumb:before{
   box-shadow: none !important;
}



