.plyr{
  width: 100%;
  height:100%;
}
 
 
.plyr__progress input[type=range]{
  display: none;
}
 
.plyr__progress__buffer{
  display: none;
}
 
.plyr__progress input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  border-radius: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  /* display: none; */
}
 
.plyr__progress input[type="range"]:hover::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  background: #413FD7;
  border-radius: 50%;
}
 
 
 
/* .plyr__volume input[type=range] {
    max-width: 60px;
} */
 
.plyr__controls {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
 
}
 
.plyr__controls  > *:not(:first-child) {
  margin-bottom: 5px;
}
 
.plyr__controls .plyr__progress__container {
  order: -1;
  flex: 1 1 100%;
  margin-bottom: 5px; /* Adjust the spacing between progress bar and other controls */
}
 
/* .plyr__control.plyr__control--overlaid[data-plyr="play"]{
  border-radius: 56px;
background: rgba(255, 255, 255, 0.50);
} */
 
.plyr__controls__item.plyr__control[data-plyr="fullscreen"]{
      position: absolute;
    /* top: 42%; */
    right: 10px;
    bottom: 0px;
}
 
/*settin icon*/
.plyr__controls__item.plyr__menu{
  position: absolute;
  /* top: 42%; */
  right: 56px;
  bottom: 0px;
}
 
 
.plyr__controls {
  background:url("../../../../assets/img/flonnect_banner.svg") right 102px bottom 13px no-repeat,linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.5))!important;
  background-size:77px auto,auto!important;
}
 
@media (max-width: 430px) {
  .plyr__controls {
    background:url("../../../../assets/img/flonnect_banner.svg") right 80px bottom 15px no-repeat,linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.5))!important;
    background-size:62px auto,auto!important;
   }
   .plyr__controls__item.plyr__control[data-plyr="fullscreen"]{
  top: 38%;
}
.plyr__controls__item.plyr__menu{
top:38%;
right:45px;
  }
 
  .plyr__volume input[type=range] {
    max-width: 45px;
    min-width:40px;
  }
}
 
.plyr__controls__item.plyr__control{
  margin-left: 6px;
}
 
 
 
 
/*background colors*/
 
.plyr__control.plyr__control--overlaid{
    background-color: #413FD7;
}
 
.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]:hover{
background-color: #413FD7;
}
 
 
 
.plyr--full-ui input[type=range]{
color: #413FD7;
}
 
.plyr__controls__item.plyr__control:hover{
    background-color: #413FD7;
}
 
.plyr--video .plyr__control[aria-expanded=true]{
    background-color: #413FD7;
}
 
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before{
    background-color: #413FD7;
}
 
 
/* cursors */
.plyr__progress>input{
    cursor: pointer;
}
 
 
 
/*speed things*/
.plyr__menu__container{
  background-color: black;
  color:white
}
 
.plyr__control.plyr__control--forward{
  color: white;
}
 
.plyr__menu__container .plyr__control[role=menuitemradio]{
color: white;
}
 
 
 
/* .plyr__controls{
  transform: translateY(52%);
} */
 
.plyr__controls{
  transform: translateY(80%);
}
 
/* .plyr--video:hover .plyr__controls{
  transform: translateY(0);
} */
 
.plyr--video.plyr--hide-controls .plyr__controls{
  transform: translateY(80%);
  opacity: 1;
}
/*
.plyr__video-wrapper:hover  .emojis{
  opacity: 1;
} */
 
 
 
.plyr__controls__item.plyr__menu{
  z-index: 999;
}
 
 
/* .plyr:fullscreen video {
  height: 100%;
} */
 
 
 
/* Ensure Plyr's container has a high z-index */
.plyr__video-wrapper {
  /* z-index: 9999; */
    background-color: rgba(82, 77, 77, 0.616);
};
 
.plyr--fullscreen-active {
  position: relative !important;
};