
.navbar{
    box-sizing: border-box !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
box-shadow: 0px 0px 6px #00000029 !important;
opacity: 1 !important;
display: flex;
}
.me-auto{
    margin-left: auto !important;
    
}
.ml-50{
    margin-left: 50px;
}
.free-tool{
    letter-spacing: 0px !important;
color: #07747C !important;
opacity: 1 !important;
font: normal normal 500 18px/24px Montserrat;
}
.download-button{
    border: 2px solid #07747C;
border-radius: 21px;
opacity: 1;
padding: 6px 27px;

background: #FFFFFF 0% 0% no-repeat padding-box !important;
font: normal normal 600 14px/27px Montserrat;
letter-spacing: 0px;
color: #07747C;
}
.header-wrapp{
    margin: 0px 0px 0px 50px !important;
    display: flex;
    justify-content: space-between;
}
.profile-img{
    width: 34px;
    border-radius: 50px;
    border: 2px solid #086972;
    padding: 1px;
    margin-right: 5px;
  }
  .profile-img-cont-prev{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .logout-text{
    font: normal normal 600 14px/19px Montserrat;
    letter-spacing: 0px;
    color: #07747C;
    opacity: 1;
    margin: 7px;
    cursor: pointer;
  }
  .user-name{
    font: normal normal normal 14px/19px Montserrat;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    margin-left: 5px;
  }